.suggestion-title-auto-complete-popup {
  :global {
    .ant-select-item-option-content {
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
    }
  }
}

.suggestion-job-title-auto-complete-container {
  display: flex;
  align-items: center;
  // background: rgb(243, 244, 245);
  border-radius: 8px;
  max-width: 270px;
  min-width: 270px;
  width: 270px;
  padding-right: 8px;

  #remove-button-id.remove-button {
    cursor: pointer;
    display: flex;
    width: 16px;
    height: 16px;
    padding: 0px 4px;
    justify-content: center;
    align-items: center;

    border: none;
    box-shadow: none;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.05);
  }

  .suggestion-title-auto-complete {
    height: 40px;

    &.ant-select-open.ant-select-focused {
      :global {
        .ant-select-selector {
          box-shadow: none;
        }
      }
    }

    :global {
      .ant-select-selector {
        height: 40px;
        box-shadow: none;
        border: none;
        transition: none;
      }
    }
  }
}
