@import "@/styles/varibles.less";

.seventh-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .main-content {
    flex-direction: column;
    gap: 24px;

    height: fit-content;
    padding: 72px;

    border-radius: 0 80px 80px 80px;

    background: linear-gradient(96.19deg, #c9f9ff 0%, #cdffed 100%);
  }
  .main-content-image {
    padding: 30px;
    border-radius: 60px;
    background-color: #fff;
  }

  .main-content-title {
    width: 800px;

    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    text-align: center;
  }
}
