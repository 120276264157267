.second-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .main-content {
    flex: 0 0 224px;
    flex-direction: column;
    gap: 40px;

    padding-block: 72px;

    background: rgba(242, 244, 245, 1);

    border-radius: 0px 80px 80px 80px;

    .main-content-jobs {
      width: 100%;
      justify-content: space-between;
    }

    .main-title {
      flex: 0 0 552px;
      height: 64px;
      padding-left: 72px;
      margin-right: auto;
      justify-self: flex-start;

      font-size: 24px;
      font-weight: 600;
      line-height: 32px; /* 133.333% */
      text-transform: capitalize;
    }

    .main-job-block {
      display: flex;
      flex-direction: column;
      justify-content: center;

      height: 80px;

      padding: 4px 68px 4px 40px;

      border-left: 2px solid rgba(0, 0, 0, 1);

      .main-job-block-count {
        font-size: 44px;
        font-weight: 700;
        line-height: 48px;
        word-break: normal;
      }

      .main-job-block-description {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
      }
    }

    .marquee {
      --gap: 0;
      position: relative;
      overflow: hidden;
      user-select: none;

      width: 100%;

      mask-image: linear-gradient(
        var(--mask-direction, to right),
        #0000,
        #000 20%,
        #000 80%,
        #0000
      );

      &:hover {
        .marquee-content {
          animation-play-state: paused;
        }
      }

      .marquee-content {
        display: flex;
        flex-shrink: 0;

        margin-block: 0;

        max-width: fit-content;

        padding: 0;

        animation: scroll 70s linear infinite;
      }
    }

    .main-job-card {
      width: 360px;
      height: 80px;
      padding-block: 10px;

      display: flex;
      align-items: center;
      gap: 16px;

      .main-job-card-logo {
        box-shadow: none;
        border-radius: 12px;
      }

      .main-job-card-company-info {
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
      }

      .main-job-card-extra-info {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;

        &::after {
          content: "hours ago";
        }
      }

      .main-job-card-title {
        max-width: 258px;

        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
      }
    }
  }
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100%));
  }
}
