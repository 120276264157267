.product-hunt-banner {
  margin-block-start: 64px;
}
div.landing-v3-content {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  min-width: 1200px;
  max-width: 1536px;
  width: calc(100% - 96px);
  min-height: 100vh;
  margin-inline: 48px;

  z-index: 9;

  .featured-by-openai {
    margin-block-start: 200px;
  }

  .main-title {
    width: 1000px;
    margin-block: 32px 16px;

    font-size: 72px;
    font-weight: 400;
    line-height: 80px;

    text-align: center;

    .emphasis {
      font-size: 72px;
      font-weight: 900;
      line-height: 80px;
      text-align: center;
      margin-block-end: 0;
      text-transform: uppercase;
    }
  }

  h2.sub-title {
    width: 720px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 40px;
  }
}

.react-player-wrapper {
  position: relative;
  border-radius: 16px;

  margin-block: 80px 48px;

  .react-player-cover {
    width: 1200px;
    height: 675px;
    border-radius: 16px;
    background-image: url(/newimages/landing/jobs_normal.jpg);
    background-position: center;
    background-size: cover;
  }

  iframe {
    border-radius: 16px;
  }
}

.default {
  transition: all 300ms linear 0s;

  &.started {
    transform: translate3d(0px, 100px, 0px);
    opacity: 0;
  }

  &.ended {
    transform: translate3d(0px, 0px, 0px);
    opacity: 1;
  }
}
