@import "@/styles/varibles.less";

.fourth-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .main-content {
    flex-direction: column;
    align-items: flex-start;

    height: fit-content;
    padding: 72px;

    border-radius: 0px 80px 80px 80px;

    background: rgba(242, 244, 245, 1);

    .main-content-title {
      width: 480px;
      height: 120px;

      font-size: 48px;
      font-weight: 700;
      line-height: 60px;

      text-transform: uppercase;
    }

    .main-content-detail {
      width: 100%;
    }

    .main-content-block {
      padding-bottom: 96px;
      border-bottom: 2px solid rgba(0, 0, 0, 0.03);

      &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
      }

      .sub-content-title {
        width: 960px;
        align-items: center;
        gap: 8px;
        text-indent: 60px;

        font-size: 40px;
        font-weight: 300;
        line-height: 48px;

        img {
          position: absolute;
        }

        b {
          font-weight: 700;
        }

        span {
          font-weight: 400;
        }
      }

      .sub-content-info {
        align-items: normal;
        justify-content: space-between;

        ul {
          display: flex;
          flex-direction: column;
          gap: 12px;
          padding: 0;
          margin: 0;
          margin-block-start: 60px;
          list-style: none;

          li {
            display: inline-flex;
            gap: 8px;

            font-size: 18px;
            font-weight: 400;
            line-height: 24px; /* 133.333% */
            text-align: left;

            &::before {
              content: " ";
              flex: 0 0 auto;
              width: 4px;
              height: 4px;
              margin-block: 10px;
              border-radius: 50%;
              background: #000000;
            }
          }
        }

        .sub-content-img {
          flex-shrink: 0;
        }
      }

      .sub-content-info-left {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

#link-button-id {
  display: flex;
  justify-content: space-between;

  width: 320px;
  height: 56px;
  padding: 16px 16px 16px 20px;
  border-radius: 28px;

  background: rgba(0, 0, 0, 1);
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.2);

  font-size: 20px;
  font-weight: 500;
  line-height: 24px;

  color: #ffffff;

  border: 0;

  &:hover {
    opacity: 0.6;
  }

  .link-button-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;

    color: #ffffff;
  }

  .link-button-icon {
    width: 24px;
    height: 24px;

    color: #ffffff;
  }
}
