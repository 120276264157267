#try-for-free-button {
  background: var(--Green, #00f0a0);
  color: #000;

  font-size: 20px;
  font-weight: 600;
  line-height: 24px; /* 120% */

  height: 56px;
  padding: 16px 24px;
  gap: 8px;
  border-radius: 28px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  box-shadow: none;
  outline: none;
}
