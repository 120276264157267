@import "@/styles/varibles.less";

.landing-page-layout.landing-page-layout-overwrite {
  width: 100%;
  min-height: 100vh;
  overflow: auto;

  background-color: #fff;
  display: flex;
  flex-flow: column nowrap;
  gap: 24px;

  background-image: url("/newimages/landing/landing_topbg.png?20240730");
  background-repeat: no-repeat;
  background-size: contain;

  .landing-v3-wrapper {
    min-height: 1371px;
    height: 1371px;
  }
}
