@import "@/styles/varibles.less";

.fifth-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .main-content {
    gap: 24px;
    flex-direction: column;
    align-items: flex-start;

    height: fit-content;
    padding: 72px;

    border-radius: 80px 0px 80px 80px;

    background: rgba(0, 240, 160, 1);

    .main-content-title {
      width: 480px;
      height: 120px;

      font-size: 48px;
      font-weight: 700;
      line-height: 60px;

      text-transform: uppercase;
    }
  }

  .main-content-sub-container {
    width: 100%;
    align-items: flex-end;
  }

  .main-content-subtitle {
    width: 720px;
    padding-block: 12px 24px;
  }

  .chat-container {
    align-items: flex-end;
  }
}

.chat-item {
  padding: 32px;
  border-radius: 24px 0px 24px 24px;

  background: rgba(255, 255, 255, 1);

  .chat-item-header {
    justify-content: space-between;
  }

  .chat-item-paragraph {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;

    font-size: 16px;
    font-weight: 400;
    line-height: 20px;

    padding-left: 24px;
    border-left: 2px solid rgba(0, 0, 0, 1);
  }

  .chat-avatar {
    border-radius: 40px;
  }

  .chat-username {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: right;
  }

  .chat-job-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: right;
  }
}
