@import "@/styles/varibles.less";

.third-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .main-content {
    flex-direction: column;
    align-items: flex-start;

    height: fit-content;
    padding: 72px;

    border-radius: 80px 0px 80px 80px;

    background: rgba(0, 240, 160, 1);

    .main-content-title {
      width: 480px;
      height: 120px;

      font-size: 48px;
      font-weight: 700;
      line-height: 60px;

      text-transform: uppercase;
    }
  }

  .main-content-sub-container {
    width: 100%;
    align-items: flex-end;
  }

  .main-content-subtitle {
    width: 720px;
    padding-block: 12px 24px;

    text-align: right;
  }

  .chat-container {
    align-items: flex-end;
  }
}

.chat-item {
  padding: 24px;
  gap: 12px;
  border-radius: 24px 0px 24px 24px;

  background: rgba(255, 255, 255, 1);

  .chat-item-paragraph {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }

  .chat-avatar {
    border-radius: 16px;
  }

  .chat-username {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
  }

  .chat-job-title {
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
  }

  .chat-video-cover {
    width: 90px;
    height: 160px;
    flex-shrink: 0;
    background-image: url("/newimages/landing/vid.jpg");
    background-position: center;
    background-size: cover;
    border-radius: 8px;
  }

  #play-button-id {
    height: 40px;
    display: flex;
    padding: 8px 32px;
    margin-block-start: 12px;
    border: 0;

    justify-content: center;
    align-items: center;
    gap: 4px;

    border-radius: 20px;
    background: var(--Black, #000);

    color: #fff;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px; /* 150% */

    &:hover {
      background: var(--Black, #000);
      border-color: var(--Black, #000);
    }
  }
}

.video-modal-container-id {
  display: flex;
  justify-content: center;
  align-items: center;

  iframe {
    border-radius: 24px;
  }

  :global {
    .ant-modal-close {
      right: -56px !important;
      background-color: #fff !important;
      border-radius: 16px !important;
    }

    .ant-modal-content {
      background-color: #000 !important;
      padding: 0 !important;
    }

    .ant-modal-body {
      display: flex;
      flex-flow: column nowrap;
      gap: 16px;
    }
  }
}
