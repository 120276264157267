@import "@/styles/varibles.less";

div.eighth-page-search-bar-select-popup {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 32px 80px 0px rgba(0, 10, 20, 0.2);
  :global {
    .rc-virtual-list-holder-inner {
      gap: 2px;

      .ant-select-item {
        span.ant-select-item-option-state {
          color: #000000;
        }
      }
    }
  }
}

.ant-select-item-option-state {
  color: #000000;
}

.eighth-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .eighth-page-content {
    flex-direction: column;
    gap: 24px;
    background: var(--Grey, #f2f4f5);

    height: fit-content;
    padding: 72px;

    border-radius: 0 80px 80px 80px;
  }

  .main-content-title {
    align-self: flex-start;
    color: var(--Black, #000);
    font-size: 48px;
    font-weight: 700;
    line-height: 60px; /* 125% */
    text-transform: uppercase;
    width: 480px;
    height: 180px;
  }

  .eighth-page-search-bar-form {
    display: flex;
    width: 100%;
    flex-flow: row nowrap;
    gap: 12px;

    :global {
      .ant-form-item {
        flex: 1;
        margin-block-end: 0;

        :global {
          .ant-select {
            border-radius: 12px;
            background: #fff;
            height: 48px;

            display: flex;
            align-items: center;
            width: 100%;

            .ant-select-selector {
              width: 100%;
              height: 48px;
              padding: 0 12px;
              border-radius: 12px;
            }

            .ant-select-selection-placeholder {
              color: var(--Black, #000);
              font-size: 14px;
              font-weight: 500;
              line-height: 24px; /* 171.429% */
            }

            .ant-select-selection-search {
              margin-inline-start: 0;
            }

            &:hover {
              .ant-select-arrow {
                opacity: 1;
              }
            }
          }
        }
      }

      .ant-form-item:last-child {
        flex: 0 0 112px;
      }
    }

    .eighth-page-search-bar-auto-complete {
      max-width: 100%;
      width: 100%;
      padding-inline-end: 0;
    }

    #eighth-page-go-btn-id.eighth-page-go-btn {
      display: flex;
      width: 112px;
      padding: 12px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      height: 48px;

      border-radius: 12px;
      background: var(--Black, #000);

      color: #fff;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px; /* 150% */
      border: none;
      transition: none;
      outline: none;
      border-color: transparent;

      &:hover {
        background: #000000cc;
        color: #fff;
      }
    }
  }
}
