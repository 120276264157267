@import "@/styles/varibles.less";

.sixth-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .main-content {
    gap: 24px;
    flex-direction: column;
    align-items: flex-start;

    height: fit-content;
    padding: 72px;

    border-radius: 0px 80px 80px 80px;

    background: rgba(242, 244, 245, 1);

    .main-content-title {
      width: 480px;
      height: 120px;

      font-size: 48px;
      font-weight: 700;
      line-height: 60px;

      text-transform: uppercase;
    }
  }

  .main-content-sub-container {
    width: 100%;
    align-items: flex-end;
  }

  .main-content-collapse {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    background-color: transparent;

    a.link {
      text-decoration: underline;
      color: #000;
    }

    :global {
      .ant-collapse-item {
        padding: 32px;
        border-radius: 24px;
        border-bottom: 0;

        background-color: #fff;

        &:last-child {
          padding: 32px;
          border-radius: 24px;
        }

        > .ant-collapse-header {
          padding: 0;

          font-size: 16px;
          font-weight: 600;
          line-height: 24px;

          h3 {
            font-size: 16px;
          }

          > .ant-collapse-expand-icon {
            width: 24px;
            height: 24px;
            padding-inline-start: 0 !important;
            border: 2px solid #000;
            border-radius: 12px;

            flex-shrink: 0;
            justify-content: center;
            align-items: center;
          }
        }

        > .ant-collapse-content {
          > .ant-collapse-content-box {
            padding: 8px 56px 0 0;

            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }
    }
  }
}
